import styles from "./Style/component.module.css";

function Footer() {
  return (
    <div className={styles.footer}>
      <p>2024 Dong & Ian Project</p>
    </div>
  );
}

export default Footer;
