// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component_header__wUeQH {
  display: flex;
  position: fixed;
  top: 0;
  left: -3px;
  right: -3px;
  bottom: 1px;

  height: 30px;
  padding: 1rem;

  font-weight: bold;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  justify-content: center;

  > p {
    font-size: 40px;
    font-weight: bold;
    display: inline;
    text-decoration-line: none;
    color: white;
    cursor: pointer;
    font-family: "Times New Roman", Times, serif;
  }
}

.component_footer__IlgMI {
  width: 100%;
  height: 30px;
  text-align: center;

  > p {
    font-size: 11px;
  }
}

.component_leftbox__htaz3 {
  position: fixed;
  left: 0;

  div {
    border: 2px solid white;
    padding: 5px 8px;
    border-radius: 50px;
    margin: 5px 0 0 25px;
    cursor: pointer;
  }

  p {
    color: white;
    font-family: "Times New Roman", Times, serif;
  }
}

.component_rightbox__e8wVH {
  padding: 8px 0 0 0;
  position: fixed;
  right: 40px;

  > img {
    width: 35px;
  }
}

.component_backbuton__iUFg- {
  cursor: pointer;
  margin: 0 0 30px 0;
}
`, "",{"version":3,"sources":["webpack://./src/Utils/Component/Style/component.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,MAAM;EACN,UAAU;EACV,WAAW;EACX,WAAW;;EAEX,YAAY;EACZ,aAAa;;EAEb,iBAAiB;EACjB,UAAU;EACV,8BAA8B;EAC9B,mBAAmB;EACnB,uBAAuB;;EAEvB;IACE,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,0BAA0B;IAC1B,YAAY;IACZ,eAAe;IACf,4CAA4C;EAC9C;AACF;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;;EAElB;IACE,eAAe;EACjB;AACF;;AAEA;EACE,eAAe;EACf,OAAO;;EAEP;IACE,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB;IACpB,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,4CAA4C;EAC9C;AACF;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;;EAEX;IACE,WAAW;EACb;AACF;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".header {\n  display: flex;\n  position: fixed;\n  top: 0;\n  left: -3px;\n  right: -3px;\n  bottom: 1px;\n\n  height: 30px;\n  padding: 1rem;\n\n  font-weight: bold;\n  z-index: 1;\n  justify-content: space-between;\n  align-items: center;\n  justify-content: center;\n\n  > p {\n    font-size: 40px;\n    font-weight: bold;\n    display: inline;\n    text-decoration-line: none;\n    color: white;\n    cursor: pointer;\n    font-family: \"Times New Roman\", Times, serif;\n  }\n}\n\n.footer {\n  width: 100%;\n  height: 30px;\n  text-align: center;\n\n  > p {\n    font-size: 11px;\n  }\n}\n\n.leftbox {\n  position: fixed;\n  left: 0;\n\n  div {\n    border: 2px solid white;\n    padding: 5px 8px;\n    border-radius: 50px;\n    margin: 5px 0 0 25px;\n    cursor: pointer;\n  }\n\n  p {\n    color: white;\n    font-family: \"Times New Roman\", Times, serif;\n  }\n}\n\n.rightbox {\n  padding: 8px 0 0 0;\n  position: fixed;\n  right: 40px;\n\n  > img {\n    width: 35px;\n  }\n}\n\n.backbuton {\n  cursor: pointer;\n  margin: 0 0 30px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `component_header__wUeQH`,
	"footer": `component_footer__IlgMI`,
	"leftbox": `component_leftbox__htaz3`,
	"rightbox": `component_rightbox__e8wVH`,
	"backbuton": `component_backbuton__iUFg-`
};
export default ___CSS_LOADER_EXPORT___;
